import FeatureBanner from 'src/components/common/feature-banner';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import appStore from 'src/stores/app-store';
import noAccessIcon from 'src/images/icons/no-access.svg';

const { features, ui, router } = appStore;

const showDisabledDocumentsNotification = (_) => {
  ui.toast({
    message: 'Documents are currently disabled',
    type: 'info',
  });
};

export const KILL_DOCS_FLAG = 'killDocuments';

export const killedDocsBannerData = {
  message:
    'Document viewer and Glide Signatures have been temporarily disabled due to maintenance',
  type: 'info',
  id: KILL_DOCS_FLAG,
};

export const DisabledDocuments = ({
  title,
  description,
  btnLabel,
  route,
  params,
}) => {
  return (
    <Fragment>
      <FeatureBanner
        title={title}
        icon={noAccessIcon}
        description={description}
        ctaProps={{
          label: btnLabel,
          onClick: () => router.navigate(route, params),
        }}
      />
    </Fragment>
  );
};

export function disableDocumentCallback(callback) {
  if (features.variation(KILL_DOCS_FLAG)) {
    return showDisabledDocumentsNotification;
  }
  return callback;
}

// component render decorator
export function renderOrShowDisabled(
  title,
  description,
  btnLabel,
  route = 'home',
  params = {}
) {
  return function (target, key, descriptor) {
    if (features.variation(KILL_DOCS_FLAG)) {
      descriptor.value = () => (
        <DisabledDocuments
          title={title}
          description={description}
          btnLabel={btnLabel}
          route={route}
          params={params}
        />
      );
    }

    return descriptor;
  };
}

export function renderOrShowNothing(target, key, descriptor) {
  if (features.variation(KILL_DOCS_FLAG)) {
    descriptor.value = () => null;
  }
  return descriptor;
}

// handy class decorator
export function performOrNotifyDisabled(target, key, descriptor) {
  if (features.variation(KILL_DOCS_FLAG)) {
    descriptor.value = showDisabledDocumentsNotification;
  }

  return descriptor;
}

DisabledDocuments.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnLabel: PropTypes.string,
  route: PropTypes.string,
  params: PropTypes.object,
};
