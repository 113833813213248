

import AppDefaultModal from './default-modal';
import AppTopBar from 'src/components/theme/app-top-bar';
import FullSpinner from 'src/components/common/full-spinner';
import Lazy from 'src/components/common/lazy';
import React from 'react';
import ScrollElement from 'src/components/router/scroll-element';
import UiGlobals from 'src/components/common/ui-globals';
import classNames from 'classnames';
import {
  KILL_DOCS_FLAG,
  killedDocsBannerData,
} from 'src/components/transactions/document-kill-switch';
import { inject, observer } from 'mobx-react';
import type UIStore from 'src/stores/ui-store';
import type AccountStore from 'src/stores/account-store';
import type RouterStore from 'src/stores/router-store';
import type FeaturesStore from 'src/stores/features-store';

type AppChromeProps = {
  ui?: UIStore;
  account?: AccountStore;
  router?: RouterStore;
  features?: FeaturesStore;
  children?: React.ReactNode;
  rootHref?: string;
  className?: string;
};

function AppChrome({
  ui,
  account,
  router,
  features,
  rootHref,
  children,
  className,
}: AppChromeProps) {
  const hasRoute = router!.hasRoute;
  const showSpinner = ui!.showSpinner || !hasRoute;

  if (showSpinner) {
    return <FullSpinner />;
  }

  const globalBanner = features!.variation('globalBanner');
  const killDocuments = features!.variation(KILL_DOCS_FLAG);

  return (
    <ScrollElement scrollElement={document.documentElement}>
      <AppDefaultModal />
      <div
        id="app-main-content"
        className={classNames('app-main-content', className, {
          'app-top-bar-visible': ui!.hasTopBar,
          'app-top-bar-hidden': !ui!.hasTopBar,
        })}
      >
        {ui!.hasTopBar && (
          <AppTopBar
            className="app-main-top-bar"
            onMenuClick={({ key }: { key: string }) => {
              if (key === 'hamburger') {
                if (ui!.isMobileSize) {
                  router!.navigate('transactions');
                } else {
                  ui!.setPopoverOpen(!ui!.popoverOpen);
                }
              }
            }}
            href={rootHref}
            showLogo
            isAuthenticated={account!.isAuthenticated}
          />
        )}
        <div id="app-page-area" className="app-page-area">
          {(!!globalBanner || !!killDocuments) && (
            <Lazy
              component={() => import('src/components/common/app-banner')}
              data={globalBanner || killedDocsBannerData}
            />
          )}
          {children}
          <UiGlobals ui={ui} />
        </div>
      </div>
    </ScrollElement>
  );
}

export default inject(
  'ui',
  'account',
  'router',
  'features'
)(observer(AppChrome));
