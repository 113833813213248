

import PartyAvatar from 'src/components/transactions/parties/party-avatar';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import fetchData from 'src/components/common/fetch-data';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-party-comment';

@inject('transactions')
@fetchData((fetch, { transactions, transactionId }) => {
  return {
    transactionLoad: transactions.getFetchTransaction(fetch, {
      transactionId,
    }),
  };
}, true)
@observer
export default class PartyComment extends Component {
  static propTypes = {
    transactionLoad: PropTypes.object,
    partyId: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  };

  render() {
    const { partyId, comment, transactionLoad } = this.props;

    if (!transactionLoad.complete) {
      // eslint-disable-next-line consistent-return
      return null;
    }

    const transaction = transactionLoad.data;
    const party = transaction.parties.getById(partyId);
    if (!party) {
      // eslint-disable-next-line consistent-return
      return null;
    }

    // eslint-disable-next-line consistent-return
    return (
      <div className={`${clsPrefix} flex-rows-align-center`}>
        <div className="margin-right-sm">
          <PartyAvatar navigates={false} party={party} size={30} />
        </div>
        <div>
          <strong> {party.firstName} says </strong> {comment}
        </div>
      </div>
    );
  }
}
