import React, { FC } from 'react';
import TopBarButtons from './top-bar-buttons';
import appStore from 'src/stores/app-store';
import { WEBINAR_LINK } from 'src/constants';
import { observer } from 'mobx-react';

const HelpPopoverIcon: FC = observer(({ className }) => {
  const { account, ui } = appStore;
  const isClient = account.user && account.user.defaultRole === 'CLIENT';

  const getHelpCenterUrl = () => {
    if (ui.isEmbedded) {
      return 'https://help.glide.com/en/articles/5677381-business-tracker-and-glide-integration-help-articles';
    }
    if (isClient) {
      return 'http://help.glide.com/filling-out-forms/';
    }
    return 'https://help.glide.com/';
  };
  const helpcenterUrl = getHelpCenterUrl();
  return (
    <TopBarButtons.PopoverIcon
      icon="help-circle"
      title="Help"
      className={className}
      content={
        <div>
          <p>
            <strong>Have a question or need help?</strong>
          </p>
          <p>
            <a href={helpcenterUrl} target="_blank" rel="noopener noreferrer">
              Help Center
            </a>
          </p>
          {!isClient && !ui.isEmbedded && (
            <p>
              <a href={WEBINAR_LINK} target="_blank" rel="noopener noreferrer">
                Daily Webinars
              </a>
            </p>
          )}
          {!ui.isEmbedded && (
            <p>
              <a href="mailto:support@glide.com">support@glide.com</a>
            </p>
          )}
        </div>
      }
    />
  );
});

export default HelpPopoverIcon;
