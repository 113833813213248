

import React from 'react';
import classNames from 'classnames';
import { CHORE_ICON_SIZE_SM, CHORE_ICON_SIZE_XS } from 'src/constants';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import PartyComment from 'src/components/transactions/parties/party-comment';
import { startChore } from 'src/models/transactions/intents';
import lightbulbImg from 'src/images/icons/lightbulbNew.svg';
import notificationWarning from 'src/images/icons/notification-warning.svg';

const clsPrefix = 'app-chore-content';
export const CHORE_CONTENT_VIEW_TYPE_BANNER = 'banner';
export const CHORE_CONTENT_VIEW_TYPE_NOTIFICATION = 'notification';

export function onChoreStart({ chore, transactions }) {
  if (chore.didStart) {
    return;
  }
  transactions.dispatch(
    chore.transactionId,
    startChore({
      choreId: chore.id,
    })
  );
}

function generateIconProps({ viewType }) {
  return {
    size:
      viewType === CHORE_CONTENT_VIEW_TYPE_NOTIFICATION
        ? CHORE_ICON_SIZE_XS
        : CHORE_ICON_SIZE_SM,
    svg:
      viewType === CHORE_CONTENT_VIEW_TYPE_NOTIFICATION
        ? notificationWarning
        : lightbulbImg,
  };
}

function ChoreContentView({
  className,
  title,
  subtitle,
  actions,
  chore,
  showComments,
  showIcon,
  description,
  choreProps,
}) {
  const { viewType } = choreProps;
  const isNotificationView = viewType === CHORE_CONTENT_VIEW_TYPE_NOTIFICATION;

  return (
    <div
      className={classNames(clsPrefix, className, 'flex-row', 'width-100', {
        [`${clsPrefix}__notification`]: isNotificationView,
      })}
    >
      {showIcon && (
        <div className={`${clsPrefix}__icon chore-icon`}>
          <AppIcon
            alt={typeof title === 'string' ? title : ''}
            type="svg"
            className="margin-right-sm"
            {...generateIconProps(choreProps)}
          />
        </div>
      )}
      <div className={`${clsPrefix}__center`}>
        {(title || subtitle) && (
          <div className={`${clsPrefix}__body chore-body`}>
            {title && (
              <div className={`${clsPrefix}__title chore-title`}>{title}</div>
            )}
            {subtitle && (
              <div className={`${clsPrefix}__subtitle chore-subtitle`}>
                {subtitle}
              </div>
            )}
          </div>
        )}
        {description && (
          <div className={`${clsPrefix}__desc`}>{description}</div>
        )}
        {actions && (
          <div className={`${clsPrefix}__actions chore-actions`}>{actions}</div>
        )}
      </div>
      {chore && chore.comment && showComments && (
        <div className={`${clsPrefix}__comments chore-comments`}>
          <PartyComment
            transactionId={chore.transactionId}
            partyId={chore.comment.partyId}
            comment={chore.comment.text}
          />
        </div>
      )}
    </div>
  );
}

ChoreContentView.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any,
  actions: PropTypes.any,
  chore: PropTypes.any,
  showComments: PropTypes.bool,
  showIcon: PropTypes.bool,
  description: PropTypes.node,
  choreProps: PropTypes.object,
};

ChoreContentView.defaultProps = {
  showComments: false,
  showIcon: true,
  choreProps: {
    viewType: CHORE_CONTENT_VIEW_TYPE_BANNER,
  },
};

export const LoadingChoreContentView = () => (
  <ChoreContentView
    title={<LoadingOutlined />}
    showIcon={false}
    subtitle=""
    actions={null}
  />
);

export default ChoreContentView;
