

/*
 * NOTE about performance
 *
 * query-select is lazy loaded because contains a significant
 * number of dependencies that increase the size of the bundle
 *
 * AppTopBar is vital for AppChrome and we ought to keep it as small as possible
 *
 * */

import HelpPopoverIcon from './help-popover-icon';
import IntercomButton from './intercom-button';
import Lazy from 'src/components/common/lazy';
import Notifications from 'src/components/transactions/chores/notifications';
// TODO: replaced with literal 'transaction' in query-select kind
// import { TRANSACTION_SEARCH  } from 'src/components/transactions/query-select';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TopBar from './top-bar';
import TopBarButtons from './top-bar-buttons';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-top-bar';

@inject('ui', 'account', 'router')
@observer
export default class AppTopBar extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    hasAvatar: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
  };

  state = {
    shortPro: false,
    animTimeout: null,
  };

  componentDidMount() {
    const { ui } = this.props;

    if (ui.isMobileSize) {
      this.setToMobile();
    }
  }

  componentWillUnmount() {
    if (this.state.animTimeout) {
      clearTimeout(this.state.animTimeout);
    }
  }

  componentDidUpdate() {
    const { ui } = this.props;
    const { shortPro } = this.state;

    if (shortPro === ui.isMobileSize) {
      return;
    }

    if (ui.isMobileSize && !this.state.animTimeout) {
      this.setToMobile();
    }

    if (!ui.isMobileSize) {
      this.setToDesktop();
    }
  }

  setToDesktop = () => {
    if (this.state.animTimeout) {
      clearTimeout(this.state.animTimeout);
    }

    this.setState({
      shortPro: false,
      animTimeout: null,
    });
  };

  setToMobile = () => {
    this.setState({
      animTimeout: setTimeout(() => {
        this.setState({
          shortPro: true,
        });
      }, 3000),
    });
  };

  render() {
    const { account, ui, ...topBarProps } = this.props;
    const { shortPro } = this.state;
    const notheme = !ui.theme;
    const search =
      account.isAuthenticated && !ui.isMobileSize ? (
        <div
          className={classNames(`${clsPrefix}__search-bar`, {
            [`${clsPrefix}__search-bar--notheme`]: notheme,
          })}
        >
          <Lazy
            component={() => import('src/components/transactions/query-select')}
            kind={['transaction']}
            key="top-bar-search"
            addTxnBtn
            placeholder="Search transactions..."
          />
        </div>
      ) : null;

    return (
      <TopBar {...topBarProps} search={search} className={clsPrefix}>
        <TopBarButtons light={!!ui.theme}>
          {account.isAuthenticated ? (
            <React.Fragment>
              {!ui.isMobileSize && (
                <TopBarButtons.LinkButton routeName="home">
                  Home
                </TopBarButtons.LinkButton>
              )}
              <IntercomButton
                className={classNames({
                  [`${clsPrefix}__hidden`]: ui.isMobileSize && !shortPro,
                })}
                iconButton={ui.isMobileSize}
              />
              {account.showUpgradePromo && (
                <TopBarButtons.Button
                  className={classNames({
                    [`${clsPrefix}__upgrade--mobile`]: ui.isMobileSize,
                  })}
                  linkClassName={classNames(`${clsPrefix}__upgrade-button`, {
                    [`${clsPrefix}__upgrade-button--mobile`]: shortPro,
                  })}
                  onClick={() => {
                    this.props.router.navigate('upgrade');
                  }}
                >
                  {shortPro ? 'Pro' : 'Upgrade'}
                </TopBarButtons.Button>
              )}
              {!ui.isMobileSize && !ui.theme && <TopBarButtons.Divider />}
              {!ui.isMobileSize && (
                <HelpPopoverIcon className={`${clsPrefix}__help`} />
              )}
              <Notifications
                className={classNames({
                  [`${clsPrefix}__hidden`]: ui.isMobileSize && !shortPro,
                })}
              />
              <TopBarButtons.DropdownAvatar
                className={`${clsPrefix}__avatar`}
              />
            </React.Fragment>
          ) : (
            <HelpPopoverIcon className={`${clsPrefix}__help`} />
          )}
        </TopBarButtons>
      </TopBar>
    );
  }
}
