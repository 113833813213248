import React, { Component, ComponentType } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { observer } from 'mobx-react';

export type FetchMode = boolean | 'auto';

export type GetterFetcher<P = any> = (mode: FetchMode, props: P) => Partial<P>;

export default function fetchData<P>(
  getterFetcher: GetterFetcher<P>,
  mountFetchMode: FetchMode = 'auto',
  updateFetchMode: FetchMode = 'auto'
) {
  function decorator<C extends ComponentType<P>>(Wrapped: C): ComponentType<P> {
    @observer
    class FetchData extends Component<P> {
      state = {}; // gets rid of warning

      render() {
        const { props } = this;
        const data = getterFetcher(false, props);
        const newProps = { ...props, ...data };
        return <Wrapped {...newProps} />;
      }

      componentDidMount() {
        getterFetcher(mountFetchMode, this.props);
      }

      componentDidUpdate() {
        getterFetcher(updateFetchMode, this.props);
      }
    }

    const componentName =
      FetchData.displayName || FetchData.name || 'Component';
    FetchData.displayName = `FetchData(${componentName})`;
    return hoistNonReactStatics(FetchData, Wrapped);
  }

  return decorator;
}
