import ContactAvatar from 'src/components/common/contact-avatar';
import Link from 'src/components/router/link';
import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';

function PartyAvatar(props) {
  const { party, showRoles, navigates, linkClassName, stopPropagation } = props;
  const nextProps = {
    ...props,
  };

  delete nextProps.party;

  if (showRoles) {
    nextProps.tags = party.roleLabelPairs.map(([_unusedRole, label]) => label);
  }

  if (navigates) {
    return (
      <Link
        routeName="transactions.transaction.parties.party"
        className={linkClassName}
        stopPropagation={stopPropagation}
        style={{
          color: 'inherit',
        }}
        routeParams={{
          transactionId: party.transactionId,
          partyId: party.id,
        }}
      >
        <ContactAvatar contact={party.contact} {...nextProps} />
      </Link>
    );
  }
  return <ContactAvatar contact={party.contact} {...nextProps} />;
}

PartyAvatar.propTypes = {
  router: PropTypes.object.isRequired,
  party: PropTypes.object.isRequired,
  stopPropagation: PropTypes.bool,
  navigates: PropTypes.bool,
  showRoles: PropTypes.bool,
  linkClassName: PropTypes.string,
};

export default inject('router')(observer(PartyAvatar));
