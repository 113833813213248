

import PromiseButton from 'src/components/common/promise-button';
import PropTypes from 'prop-types';
import React from 'react';

const clsPrefix = 'app-feature-banner';

function FeatureBanner({
  className,
  compact,
  icon,
  title,
  description,
  children,
  ctaProps,
}) {
  return compact ? (
    <div className={`${className} ${clsPrefix}__compact`}>
      {icon && (
        <div
          className={`${clsPrefix}__icon`}
          style={{
            backgroundImage: `url(${icon})`,
          }}
        />
      )}
      <div className={`${clsPrefix}__content`}>
        {title && <h2 className={`${clsPrefix}__title`}>{title}</h2>}
        {description && (
          <div className={`${clsPrefix}__description`}>{description}</div>
        )}
        {children}
        {ctaProps && (
          <div className={`${clsPrefix}__ctas`}>
            <PromiseButton
              size="large"
              type="primary"
              onClick={ctaProps.onClick}
            >
              {ctaProps.label}
            </PromiseButton>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={`${className} ${clsPrefix}__full`}>
      {icon && <img src={icon} alt="" className="margin-bottom-sm" />}
      {title && <div className={`${clsPrefix}__title`}>{title}</div>}
      {description && (
        <div className="margin-top-xs margin-bottom-sm">{description}</div>
      )}
      {children}
      {ctaProps && (
        <PromiseButton
          type="primary-outline"
          size="large"
          onClick={ctaProps.onClick}
        >
          {ctaProps.label}
        </PromiseButton>
      )}
    </div>
  );
}

FeatureBanner.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.object,
  ctaProps: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default FeatureBanner;
