

/*
 * NOTE about performance
 *
 * ChoreListPane is lazy loaded because contains a significant
 * number of dependencies that increase the size of the bundle
 *
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import partial from 'lodash/partial';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Lazy from 'src/components/common/lazy';
import SizeContext from 'src/components/common/size-context';
import TopBarButtons from 'src/components/theme/top-bar-buttons';
import { CHORE_CONTENT_VIEW_TYPE_NOTIFICATION } from './kinds/chore-content-view';

const clsPrefix = 'app-notifications';

@inject('router', 'transactions', 'account')
@observer
export default class Notifications extends Component {
  static propTypes = {
    transactions: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  state = {
    visible: false,
  };

  handlePopup = () => {
    const { account, transactions } = this.props;
    transactions.chores.markAsRead(account);
  };

  handleVisibleChange = (visible) => {
    this.setState({
      visible,
    });

    if (visible) {
      this.handlePopup();
    }
  };

  render() {
    const { transactions, account, className } = this.props;
    const choresStore = transactions.chores;
    const count = choresStore ? choresStore.count : 0;

    /* Chores store is optional since we want at least the icon to be displayed without it */
    const choresStoreDependantProps = choresStore
      ? {
          unreadChores: choresStore.unreadChores,
          items: choresStore.getChores(account, {
            exceptKinds: ['COMPLETE_SSO'],
            noHidden: true,
          }),
          fetchMore: partial(transactions.chores.fetchMore),
          hasMore: transactions.chores.hasMore,
        }
      : {};

    return (
      <TopBarButtons.PopoverIcon
        className={classNames(clsPrefix, className)}
        icon="bell"
        badge={count ? +count : 0}
        overlayClassName={`${clsPrefix}__popover`}
        title="Notifications"
        onVisibleChange={this.handleVisibleChange}
        content={
          <div className={`${clsPrefix}__details`}>
            <SizeContext.Provider value="small">
              <Lazy
                component={() =>
                  import('src/components/transactions/chores/chore-list-pane')
                }
                visible={this.state.visible}
                {...choresStoreDependantProps}
                borderless
                emptyMessage={
                  <div className={`${clsPrefix}__empty-msg`}>
                    Nothing right now
                  </div>
                }
                choreProps={{
                  showDescription: true,
                  showComments: false,
                  viewType: CHORE_CONTENT_VIEW_TYPE_NOTIFICATION,
                }}
              />
            </SizeContext.Provider>
          </div>
        }
      />
    );
  }
}
