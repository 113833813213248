import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject } from 'mobx-react';

@inject('router')
export default class ScrollElement extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    children: PropTypes.any,
    scrollElement: PropTypes.any,
  };

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }

  componentWillUnmount() {
    this.props.router.scrollElement = this.lastScrollElement;
  }

  componentDidMount() {
    const router = this.props.router;
    this.lastScrollElement = router.scrollElement;
    router.scrollElement = this.props.scrollElement;
  }
}
