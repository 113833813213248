import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getDisplayName from 'src/utils/get-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

const SizeContext = React.createContext('default');

export const sizeContextConsumer = (WrappedComponent) => {
  class SizeContextConsumer extends Component {
    static propTypes = {
      size: PropTypes.string,
    };

    state = {};

    setWrappedRef = (wrappedRef) => {
      this.wrappedRef = wrappedRef;
    };

    render() {
      return (
        <SizeContext.Consumer>
          {(contextSize) => (
            <WrappedComponent
              ref={this.setWrappedRef}
              {...this.props}
              size={this.props.size || contextSize}
            />
          )}
        </SizeContext.Consumer>
      );
    }
  }

  hoistNonReactStatics(SizeContextConsumer, WrappedComponent);
  SizeContextConsumer.displayName = `SizeContextConsumer(${getDisplayName(
    WrappedComponent
  )})`;
  return SizeContextConsumer;
};

export default SizeContext;
