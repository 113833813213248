import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TopBarButtons from './top-bar-buttons';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

@inject('account')
@observer
export default class IntercomButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    account: PropTypes.object.isRequired,
    iconButton: PropTypes.bool,
    label: PropTypes.string,
  };
  static defaultProps = {
    label: 'Support',
  };

  Intercom = (...args) => {
    return window.Intercom && window.Intercom(...args);
  };

  render() {
    const { account, iconButton, className, label, ...otherProps } = this.props;
    const buttonProps = {
      badge: account.intercomUnreadCount,
      onClick: () => this.Intercom('show'),
      ...otherProps,
    };

    if (iconButton) {
      return (
        <TopBarButtons.IconButton
          title={label}
          {...buttonProps}
          className={classNames('app-intercom-button', className)}
          icon="message-square"
        />
      );
    }

    return (
      <TopBarButtons.TextButton className={className} {...buttonProps}>
        {label}
      </TopBarButtons.TextButton>
    );
  }
}
