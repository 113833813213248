import ContactAvatar from './contact-avatar';
import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

function UserAvatar(props) {
  const { user, ...nextProps } = props;
  return (
    <ContactAvatar
      contact={user.contact}
      ghost={user.state === 'INACTIVE'}
      {...nextProps}
    />
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default observer(UserAvatar);
